.react-calendar {
  @apply text-amber-900 border-amber-900 border;
}

.react-calendar--doubleView {}

.react-calendar--doubleView .react-calendar__viewContainer {}

.react-calendar--doubleView .react-calendar__viewContainer>* {}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {}

.react-calendar button {}

.react-calendar button:enabled:hover {
  @apply bg-amber-300;
}

.react-calendar__navigation {}

.react-calendar__navigation button {
  @apply rounded-md border bg-white px-4 py-2 text-sm font-medium text-amber-900 shadow-sm border-amber-900;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  @apply bg-amber-300;
}

.react-calendar__month-view__weekdays {}

.react-calendar__month-view__weekdays__weekday {}

.react-calendar__month-view__weekNumbers .react-calendar__tile {}

.react-calendar__month-view__days__day--weekend {
  @apply text-amber-600;
}

.react-calendar__month-view__days__day--neighboringMonth {
  @apply text-amber-400;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {}

.react-calendar__tile {}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  @apply bg-amber-100;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  @apply bg-amber-600 text-white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  @apply bg-amber-600 text-white;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}